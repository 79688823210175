const Api = {
    // host: 'http://150.95.90.71:9092',
    // host: 'http://150.95.89.3:9092',
    host: 'https://edyamm.com:9092',
    invoice_host: 'http://localhost:9092',

    login: 'api/auth/login',
    refresh_token: 'api/auth/refresh-token',

    admin: 'admin',
    admin_filter: 'admin/filter',

    company: 'company',
    company_filter: 'company/filter',
    company_id_1: 'companyId=1',

    faq: 'faq',
    faq_filter: 'faq/filter',

    nearby: 'nearby',
    nearby_filter: 'nearby/filter',

    driver: 'driver',
    driver_filter: 'driver/filter',
    driver_assign_vehicle: 'driver/assign-vehicle',
    driver_unassign_vehicle: 'driver/unassign-vehicle',

    vehicle: 'vehicle',
    vehicle_filter: 'vehicle/filter',

    fuel_station: 'fuel-station',
    fuel_station_filter: 'fuel-station/filter',

    fuel_staff: 'fuel-staff',
    fuel_staff_filter: 'fuel-staff/filter',

    fill_point : 'company/point-fill',

    fuel_point_filter : 'fuel-point/request/filter-by-company',
    fuel_point_approve : 'fuel-point/approve',

    fuel_point_history : "/fuel-point-history",

    point_history_filter_by_station : '/fuel-point-history/filter-by-fuel-station',

    deduct_point : '/admin/fuel-station/deduct-fuel-point',

    common : '/common',


};
export default Api;
