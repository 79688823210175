import { EditOutlined, PlusCircleFilled } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Col, DatePicker, InputNumber, message, Modal, Row, Select, Skeleton, Space, Table, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { MvButton } from '../../components/common/Button/Button';
import { EdSearchBox } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import { constant } from '../../constants/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';

const { Option } = Select;

class DriverPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminId: this.props.params.adminid,
      deleteItem: "",
      companyId: '',
      companyName: '',
      data: [],
      page: constant.page,
      pageSize: constant.pageSize,
      pageNumber: 0,
      totalPagination: 0,
      searchValue: [],
      data_filter_params: '',
      prevSearchTerm: '',
      pageNumber: '',
      selectedPlateNumber: undefined,
      loading: true,
      detailVisible: false,
      assignVisible: false,
      selectedDriverId: null,
      selectedVehicleId: '',
      vehicleOptions: [],
      vehicleDetailValue: '',
      vehicleDetailId: null,
      pointValue: null,
      assign: false,
      columns: [
        {
          title: 'No.',
          width: 5,
          dataIndex: '',
          key: 'no',
          render: (value, item, index) => <span>{index + 1}</span>,
        },
        // {
        //   title: i18next.t('driver_unique_id'),
        //   dataIndex: 'uniqueId',
        //   key: '2',
        // },
        {
          title: i18next.t('driver_name'),
          dataIndex: 'fullName',
          key: '3',
        },
        {
          title: i18next.t('phone_number'),
          dataIndex: 'phoneNumber',
          key: '4',
        },
        {
          title: i18next.t('vehicle.detail'),
          dataIndex: 'vehicle',
          key: '5',
          render: (vehicle, driver) => {
            if (vehicle && vehicle.id) {
              return <a onClick={() => this.handleDetail(driver.id, vehicle, vehicle.id)}>{i18next.t("vehicle.detail")}</a>;
            } else {
              return <a onClick={() => this.handleDetail(driver.id)}>{i18next.t('unassign')}</a>;
            }
          }
        },
        {
          title: 'Current Points',
          dataIndex: 'fuelPoint',
          key: 'fuelPoint'
        },
        {
          title: 'Add Points',
          dataIndex: 'addPoints',
          key: 'addPoints',
          width: 60,
          render: (driver, record) => (
            <Space style={{ alignItems: 'center' }}>
              <InputNumber value={record.addPoints} onChange={(value) => record.addPoints = value} style={{ marginRight: 0, width: 100 }} />
              <Button type="primary" onClick={() => this.handleAddPoints(record.id, record.addPoints)}>Add</Button>
            </Space>
          )
        },
        {
          title: i18next.t('status'),
          dataIndex: 'status',
          key: '6',
          render: (status) => (
            <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>
              {status}
            </Tag>
          ),
        },
        {
          title: i18next.t('action'),
          dataIndex: '',
          width: 40,
          textAlign: "center",
          key: '7',
          render: (driver) => (
            <Space>
              <Link to={`${CustomRoute.admin}/${this.state.adminId}/${CustomRoute.company_param}/${this.state.companyId}/${CustomRoute.driver_update}/${driver.id}`}>
                <Button size={"medium"} type="primary" shape="circle">
                  <EditOutlined />
                </Button>
              </Link>
            </Space>
          )
        },
      ],
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: i18next.t('driver'),
        },
      ],

    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchVehicle();
  }

  fetchData = async (pageNumber) => {
    const { page, user_filter_params, companyId } = this.state;
    const companyid = this.props.companyId

    this.setState({
      companyId: companyid
    })

    try {
      const params = {
        ...user_filter_params,
        companyId: this.props.companyId,
        page: pageNumber ? pageNumber - 1 : page - 1,
      };

      const response = await ApiInstance(Api.driver_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

      if (response && response.content) {
        this.setState((prevState) => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: pageNumber ? pageNumber : page,
          loading: false
        }));
      }
    } catch (error) {
      this.setState({
        loading: false
      })
    }
  };

  search = async () => {
    const { page, pageNumber, searchTerm, data_filter_params } = this.state
    this.setState({
      isDataFetching: true
    })
    try {
      const params = {
        ...data_filter_params,
        companyId: this.props.companyId,
      };

      const response = await ApiInstance(Api.driver_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: page,
          prevSearchTerm: searchTerm
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false
    })
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  changeSearchName = (event, searchName) => {
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      [searchName]: event.target.value,
    }
    this.setState({
      data_filter_params: data_filter_params,
      searchTerm: event.target
    });

    if (event.target.value === '' && this.state.prevSearchTerm) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          [searchName]: '',
        },
        prevSearchTerm: ''
      }, () =>
        this.search()
      );
    }
  }
  changeDateOfBirth = (date, dateString) => {
    console.log(date, dateString);
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      dateOfBirth: dateString,
    }
    this.setState({
      data_filter_params: data_filter_params,
    });
  }

  handleKeyDown = (e) => {
    this.search();
  };

  fetchVehicle = async (pageNumber) => {
    const { page, user_filter_params, companyId } = this.state;
    const params = {
      ...user_filter_params,
      companyId: this.props.companyId,
      page: pageNumber ? pageNumber - 1 : page - 1,
    };

    await ApiInstance(Api.vehicle_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      .then(response => {
        this.setState({
          vehicleOptions: response?.content
        })
      })
      .catch(error => {
      })
  }

  updateVehicle = async (values) => {

    const { selectedDriverId, selectedVehicleId, vehicleDetailId, assign } = this.state

    const requestData = {
      "id": selectedDriverId,
      "vehicle": {
        "id": selectedVehicleId
      }
    }
    try {
      const response = await (assign ? ApiInstance(Api.driver_assign_vehicle, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null) :
        ApiInstance(Api.driver_unassign_vehicle, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, null, null, selectedDriverId));

      if (response.status == 200) {
        this.fetchData();
      }
    } catch (error) { }
    this.setState({
      selectedDriverId: '',
      selectedVehicleId: '',
      vehicleDetailId: '',
      vehicleDetailValue: '',
    })
  };

  handleDetail = (driverId, vehicle, vehicleId) => {
    this.setState({
      selectedDriverId: driverId,
      vehicleDetailId: vehicleId,
    },
      () => {
        if (vehicle) {
          this.setState({
            detailVisible: true,
            vehicleDetailValue: vehicle,
          })
        }
        else {
          this.setState({
            assignVisible: true,
          })
        }
      }
    )
  }

  handleVehicleChange = (value) => {

    this.setState({
      selectedVehicleId: value,
      selectedPlateNumber: value
    })
  };

  handleAssign = () => {

    this.setState({
      detailVisible: false,
      assignVisible: false,
      selectedPlateNumber: undefined,
      assign: true
    }, () =>
      this.updateVehicle()
    )
  };

  handleUnassign = () => {

    this.setState({
      visible: false,
      detailVisible: false,
      selectedPlateNumber: undefined,
      assign: false
    }, () =>
      this.updateVehicle()
    )
  };

  handleCancel = () => {
    this.setState({
      detailVisible: false,
      assignVisible: false,
      selectedVehicleId: '',
      selectedDriverId: '',
      vehicleDetailId: '',
      vehicleDetailValue: '',
      selectedPlateNumber: undefined
    })
  }

  handleAddPoints = async (id, fillPoints) => {

    this.setState({
      on_fill_loading: true
    })

    const requestData = {
      "fuelPointRequestDTOList": [
        {
          "driver": {
            "id": id
          },
          "topUpPoint": fillPoints
        }
      ]
    }
    try {
      const response = await ApiInstance(
        Api.fill_point, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        message.success("Successfully Filled")
        this.fetchData();
        this.setState({
          on_fill_loading: false,

        })
      }
    } catch (error) {
      console.log(error);

      this.setState({
        on_fill_loading: false
      })
    }

  }

  render() {
    const { totalPagination, pageSize, page, data, columns, vehicleDetailValue,
      assignVisible, detailVisible, selectedVehicleId, vehicleOptions, companyId,
      adminId, selectedPlateNumber, loading } = this.state;
    const options = vehicleOptions.map(vehicle => ({
      value: vehicle.id,
      label: vehicle.licensePlateNumber,
    }));


    return (
      <>
        <Modal
          title={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>{i18next.t('vehicle.detail')}</span>}
          width={800}
          visible={detailVisible}
          onCancel={this.handleCancel}
          footer={[
            <>
              {selectedVehicleId ?
                <Button
                  type="primary"
                  onClick={this.handleAssign}
                  style={{ backgroundColor: "blue" }}
                >
                  {i18next.t("assign")}
                </Button>
                :
                <Button onClick={this.handleUnassign} type="primary" danger>{i18next.t("unassign")}</Button>
              }
            </>,
          ]}
        >
          <Row style={{ marginBottom: '10px' }}>
            <Col span={10}>{i18next.t("vehicle.id")}</Col>
            <Col span={14}>{vehicleDetailValue.id}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={10}>{i18next.t('model')}</Col>
            <Col span={14}>{vehicleDetailValue.model}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={10}>{i18next.t('model_year')}</Col>
            <Col span={14}>{vehicleDetailValue.modelYear}</Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col span={10}>{i18next.t("license_plate_number")}</Col>
            <Col span={14}>{vehicleDetailValue.licensePlateNumber}</Col>
          </Row>
          <h3>Assign Vehicle To Driver</h3>
          <Select
            showSearch
            style={{ width: '100%', height: 40 }}
            placeholder={i18next.t('choose_vehicle')}
            optionFilterProp="label"
            onChange={this.handleVehicleChange}
            value={selectedPlateNumber}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={options}
          />
        </Modal>
        <Modal
          title={<span style={{ fontSize: '20px', fontWeight: 'bold' }}>Assign Vehicle To Driver</span>}
          width={800}
          visible={assignVisible}
          onCancel={this.handleCancel}
          footer={[
            <Button
              type="primary"
              onClick={selectedVehicleId ? this.handleAssign : this.handleUnassign}
              style={{ backgroundColor: "blue" }}
            >
              {i18next.t("assign")}
            </Button>
          ]}
        >
          <Select
            showSearch
            style={{ width: '100%' }}
            placeholder="Choose Vehicle"
            optionFilterProp="label"
            onChange={this.handleVehicleChange}
            value={selectedPlateNumber}
            allowClear
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={options}
          />
        </Modal>

        <Row gutter={[16, 16]}>
          <Col span={8}>
            <TitleLevel3 label={i18next.t("driver")} />
          </Col>
          <Col span={16} style={{ textAlign: "end" }}>
            <MvButton path={`${CustomRoute.admin}/${adminId}/${CustomRoute.company_param}/${companyId}/${CustomRoute.driver_create}`} icon={<PlusCircleFilled />}></MvButton>
          </Col>
          <Col span={4}><EdSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "fullName")}
            placeholder="SearchByName"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}><EdSearchBox
            size="middle"
            onChange={(values) => this.changeSearchName(values, "phoneNumber")}
            placeholder="SearchByPhoneNumber"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}>
            <DatePicker placeholder={i18next.t('select_date')} style={{ height: '40px', width: '100%' }} onChange={(date, dateString) => {
              this.changeDateOfBirth(date, dateString)}}/>
          </Col>
          <Col span={12}>
            <Button size="large" shape="round" onClick={() => this.search()} type="primary" >{i18next.t('search')}</Button>
          </Col>
          <Col span={24}>
            {
              loading ? (
                <Skeleton active />) : (
                <Table
                  pagination={{
                    position: "top" | "bottom",
                    total: totalPagination,
                    current: page,
                    onChange: this.handlePaginationChange,
                    defaultPageSize: pageSize,
                  }}
                  dataSource={data} columns={columns} />
              )
            }
          </Col>
        </Row>
      </>
    )
  }
}


export default compose(withRouter(DriverPage))