const accessTokenKey = 'edya-access-token'
const refreshTokenKey = 'edya-refresh-token'
const authenticateKey = 'edya-authenticate-key'


// Encoding function
const encodeValue = (value) => {
    return btoa(value); // Base64 encode the value
  };
  
  // Decoding function
  const decodeValue = (encodedValue) => {
    return atob(encodedValue); // Decode Base64-encoded value
  };

export const LOC_store_token = (data) => {
    if (data) {
        const encodedAccessToken = encodeValue(data.accessToken);
        const encodedRefreshToken = encodeValue(data.refreshToken);
        localStorage.setItem(accessTokenKey, encodedAccessToken);
        localStorage.setItem(refreshTokenKey, encodedRefreshToken);
        localStorage.setItem(authenticateKey, true);
        
    }
}

export const LOC_clear_token = () => {
    localStorage.clear();
}
    
export const LOC_getAccessToken = () => {
  const accessToken = localStorage.getItem(accessTokenKey)
  let decodeToken = null
  if (accessToken && accessToken !== null && accessToken !== undefined) {
    decodeToken = decodeValue(accessToken)
  }
  return decodeToken;
}



export const LOC_getAuthenticate = () => localStorage.getItem(authenticateKey);

export const LOC_getRefreshToken = () => decodeValue(localStorage.getItem(refreshTokenKey));



