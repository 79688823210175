import { ConfigProvider, DatePicker, Input, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import i18next from "i18next";
import React from 'react';
import './input.css';

export const MvInput = ({ type, ...props }) => {
  return (
    <Input
      className="Mvinput"
      type={type}
      size="large"
      {...props}
      style={{
        backgroundColor: "#f5f5f5",
        border: "none",
        height: "40px",
        boxShadow: "none",
      }}
    />
  );
};

export const MvPasswordBox = ({ ...props }) => {
  return (
    <div className="mv-password" style={{ borderRadius: '8px' }}>
      <Input.Password style={{ border: 'none', boxShadow: 'none', borderRadius: '20px' }} {...props} />
    </div>
  );
};

export const MvTextarea = ({ ...props }) => {
  return (
    <TextArea
      {...props}
      style={{
        border: "none",
        boxShadow: "none",
      }}
      autoSize={{ minRows: 3, maxRows: 5 }}
    />
  );
};

export const MvSelectBox = () => {
  <Select
    placeholder="Select Active or Inactive"
  >
    <Option value="male">male</Option>
    <Option value="female">female</Option>
    <Option value="other">other</Option>
  </Select>
}

export const MvDatePicker = ({ handleDateChange, value, ...datepickerProps }) => {
  const { bgcolor } = datepickerProps;
  
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorPrimaryHover: '#fff',
        cellWidth: 100,
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        DatePicker: {
          activeShadow: 'none',
          borderRadius: '8px',
          activeBorderColor: '#fff'
        },
      }
    }}
    >
      <DatePicker
        // placeholder={i18next.t('select_date')}
        style={{ height: '40px', width: '100%' }}
        placeholder={value==null?i18next.t('select_date'):value}
        onChange={handleDateChange}
      />
    </ConfigProvider>
  )
}

export const MvLicensePlateInput = ({ value = '', onChange, type }) => {
  const handleChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.toUpperCase();
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2).replace(/[^0-9]/g, '');
    } else {
      inputValue = inputValue.replace(/[^A-Z0-9]/g, '');
    }
    onChange(inputValue);
  };

  return (
    // <ConfigProvider theme={{
    //   token: {
    //     colorBorder: '#fff',
    //     colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
    //     colorPrimaryHover: '#f5f5f5',
    //     colorPrimary: 'rgb(128, 128, 128)',
    //   },
    //   components: {
    //     Input: {
    //       activeShadow: '0 0 0 0.5px rgb(128,128,128)',
    //       borderRadius: 9,
    //       borderRadiusLG: 9,
    //     },
    //     InputNumber: {
    //       activeShadow: '0 0 0 0.5px rgb(128,128,128)',
    //       borderRadius: 9,
    //       borderRadiusLG: 9,
    //     }
    //   },
    // }}>
    <Input
      className="Mvinput"
      placeholder="LicensePlateNumber"
      type={type}
      style={{
        backgroundColor: "#f5f5f5",
        border: "none",
        height: "40px",
        boxShadow: "none",
      }}
      size="large"
      maxLength={7}
      value={value}
      onChange={handleChange}
    />
    // </ConfigProvider>
  );
};

